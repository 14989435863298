import React from "react"
import { Link } from "gatsby"
import { generatePath } from "../lib/helpers"
import WebsiteMeta from "../components/WebsiteMeta"
import FadeIn from "../components/FadeIn"
import styled from "styled-components"

export default function Success() {
  return (
    <>
      <WebsiteMeta titleFull="Success!" />
      <FadeIn direction="up">
        <PageTitle>Success!</PageTitle>
      </FadeIn>
      <FadeIn direction="down" delay={250}>
        <Subheader>Your message has been sent successfully.</Subheader>
      </FadeIn>
      <FadeIn direction="down" delay={300} style={{ marginTop: `3rem` }}>
        <Link to={generatePath("/", "page")} className="button icon">
          Back to Home<span>&rarr;</span>
        </Link>
      </FadeIn>
    </>
  )
}

const PageTitle = styled.h1`
  margin: 0;
`

const Subheader = styled.p`
  margin: 0.25rem 0 1.25rem 0;
  color: var(--gray);
`
